import React, { useRef, useState, useEffect, useMemo } from 'react';
import './css/home_page.css';
// import ParticlesComponent from './particles';
import { useHistory, useNavigate } from 'react-router-dom';
import Airtable from 'airtable';




function Home_Page() {


 // Alert state and functions
 const [alertVisible, setAlertVisible] = useState(false);  // Track alert visibility
 const [alertMessage, setAlertMessage] = useState('');

 // Function to show the custom alert
 const showAlert = (message) => {
   setAlertMessage(message);
   setAlertVisible(true);  // Display the alert
 };

 // Function to close the alert box
 const closeAlert = () => {
   setAlertVisible(false);  // Hide the alert
 };

  const formRef = useRef(null);

  // Handle scroll to the form section
  const handleScrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // Visuals
  const [animate, setAnimate] = useState(false);
  const [shake, setShake] = useState(false);
  const [showText, setShowText] = useState(false);
  // const [particlesKey, setParticlesKey] = useState(0);

  useEffect(() => {
    if (!animate) {
      setAnimate(true); // Start animation once
    }
  }, [animate]);

  useEffect(() => {
    if (animate && !shake) {
      setTimeout(() => {
        setShake(true); // Trigger shake animation after circles meet
      }, 2000); // Add delay to start shake after 2 seconds
    }
  }, [animate, shake]);

  useEffect(() => {
    if (shake) {
      setTimeout(() => {
        setShowText(true); // Show text after shake completes
      }, 500); // Delay before showing text
    }
  }, [shake]);

  // PARTICLES
  // const [resetParticles, setResetParticles] = useState(false);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setResetParticles(prev => !prev);  // Toggle state to trigger reset
  //   }, 120000); // Reset every 2 minutes
  
  //   return () => clearInterval(intervalId); // Clean up interval on component unmount
  // }, []);
  // const particleOptions = useMemo(() => ({
  //   background: { color: { value: "transparent" } },
  //   fullScreen: { enable: false, zIndex: -1 },
  //   fpsLimit: 120,
  //   particles: {
  //     color: { value: "#FFFFFF" },
  //     shape: { type: ["circle", "triangle", "polygon"] },
  //     links: {
  //       color: "#ffcfee",
  //       distance: 120,
  //       enable: true,
  //       opacity: 0.3,
  //       width: 1,
  //     },
  //     move: {
  //       random: false,
  //       enable: true,
  //       speed: 0.5,
  //       direction: "none",
  //       straight: false,
  //       outModes: { default: "bounce" },
  //     },
  //     number: { value: 100 },
  //     opacity: { value: 0.3 },
  //     size: { value: { min: 2, max: 4 } },
  //   },
  //   detectRetina: true,
  // }), []);
  // const resetParticleOptions = useMemo(() => ({
  //   background: { color: { value: "transparent" } },
  //   fullScreen: { enable: false, zIndex: -1 },
  //   fpsLimit: 120,
  //   particles: {
  //     color: { value: "#FFFFFF" },
  //     shape: { type: ["circle", "triangle", "polygon"] },
  //     links: {
  //       color: "#ffcfee",
  //       distance: 120,
  //       enable: true,
  //       opacity: 0.3,
  //       width: 1,
  //     },
  //     move: {
  //       random: false,
  //       enable: true,
  //       speed: 0.5,
  //       direction: "none",
  //       straight: false,
  //       outModes: { default: "bounce" },
  //     },
  //     number: { value: 100 },
  //     opacity: { value: 0.3 },
  //     size: { value: { min: 2, max: 4 } },
  //   },
  //   detectRetina: true,
  // }), []);

  // Text moving around circles
  const circleTexts1 = [
    "Your ideas.", 
    "Your vision.", 
    "Your passion.", 
    "Your dedication.", 
    "Your unique skills.", 
    "Your creative energy.", 
    "Your bold strategy.", 
    "Your leadership.", 
    "Your knowledge.", 
    "Your determination.", 
    "Your bold dreams.", 
    "Your innovation.", 
    "Your persistence.", 
    "Your fresh perspective.", 
    "Your focus.",
    "Your endless curiosity.", 
    "Your love for growth.", 
    "Your detailed planning.", 
    "Your risk-taking.", 
    "Your empathy.", 
    "Your unique mindset.", 
    "Your fresh ideas.", 
    "Your deep research.", 
    "Your perseverance.", 
    "Your adaptability.", 
    "Your clarity of goals.", 
    "Your energy.", 
    "Your storytelling.", 
    "Your technical knowledge.", 
    "Your optimistic outlook.", 
    "Your inspiring work ethic.", 
    "Your network.", 
    "Your big-picture thinking.", 
    "Your structured approach.", 
    "Your hunger for impact.", 
    "Your ability to inspire.", 
    "Your integrity.", 
    "Your confidence.", 
    "Your proactive attitude."
  ];
  
  const circleTexts2 = [
    "Your co-founder's experience.", 
    "Your team's clarity.", 
    "Their shared goals.", 
    "Their hard work.", 
    "Their technical expertise.", 
    "Their practical approach.", 
    "Their logical execution.", 
    "Their support.", 
    "Their problem-solving.", 
    "Their reliability.", 
    "Their grounded reality.", 
    "Their collaborative mindset.", 
    "Their perseverance.", 
    "Their broader view.", 
    "Their adaptability.",
    "Their willingness to learn.", 
    "Their unique insights.", 
    "Their strategic alignment.", 
    "Their fearless execution.", 
    "Their emotional intelligence.", 
    "Their flexible approach.", 
    "Their practical skills.", 
    "Their dedication to progress.", 
    "Their attention to detail.", 
    "Their unshakable focus.", 
    "Their confidence in action.", 
    "Their mentoring abilities.", 
    "Their accountability.", 
    "Their goal-oriented mindset.", 
    "Their networking skills.", 
    "Their reliable systems.", 
    "Their structured execution.", 
    "Their humility.", 
    "Their drive for success.", 
    "Their collaborative spirit.", 
    "Their technical precision.", 
    "Their understanding of markets.", 
    "Their disciplined planning."
  ];

  const [textIndex, setTextIndex] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFadingOut(true);
      setTimeout(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % circleTexts1.length);
        setIsFadingOut(false); // Fade back in
      }, 2200); // Fade-out duration
    }, 4400); // Total cycle duration

    return () => clearInterval(interval); // Cleanup on unmount
  }, [circleTexts1.length]);



  // const ParticlesMemo = React.memo(ParticlesComponent);

//video 
const videoNode = document.getElementById('.background-video');
if (videoNode) {
  videoNode.addEventListener('click', function(event){
    event.preventDefault();
  });
}

  // EMAIL SUBMISSION WITH AIRTABLE
 // Initialize Airtable base
 const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;
 const base = new Airtable({ apiKey }).base('appKcpbUdsxyXqNcT'); // Your Airtable Base ID
const table = base('Waitlist1'); // Your table name



// Function to validate email format
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};


  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    const emailInput = formRef.current.querySelector("#emailInput");
    const email = emailInput.value;

    const checkbox = document.getElementById('consentCheckbox');

    if (!checkbox.checked) {
        showAlert("Please agree to the Privacy Policy before signing up.");
        return;
    }
    
    // Email validation
    if (!email || !isValidEmail(email)) {
      showAlert("Please enter a valid email!");
      return;
    }

    // Insert into Airtable
    try {
      const records = await base('Waitlist1') // Replace 'Waitlist' with your table name
        .select({
          filterByFormula: `{Email} = "${email}"`,
        })
        .firstPage();

      if (records.length > 0) { 
        
        showAlert("You are already in our waitlist. Congratulations on being one of the early members of Microsaas Founders Network!")
        emailInput.value = ""; // Clear the input after successful submission
        setTimeout(() => {
          navigate("/waitlist_successful"); // Navigate after alert
        }, 3000); // Wait 3 seconds
      
        return
        
      }
      // Get current date and time
      const date = new Date();  // Get current date and time
const formattedDate = date.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,  // true for 12-hour format, false for 24-hour
});

      // Insert the email and submission date into Airtable
      await table.create([
        {
          fields: {
            'Email': email,
            'Email Submission Date': formattedDate,
          },
        },
      ]);

      // If data was successfully added, navigate to the success page
      
      emailInput.value = ""; // Clear the input after successful submission
      showAlert("Welcome to the early Microsaas Founders network! We received your form submission.");
    
      
          
        // Simulate delay before navigating
  setTimeout(() => {
    navigate("/waitlist_successful"); // Navigate after alert
  }, 3000); // Wait 3 seconds
   // Now send the email in the background (after navigating)
   
    // try {
    //       const response = await fetch("/.netlify/functions/email-server", {
    //         method: "POST",
    //         body: JSON.stringify({
    //           email: email,  // Send the email address
    //           subject: "Welcome to the Microsaas Founders Network!",
    //           body: `Hi there,
        
    //     Thank you for joining the Microsaas Founders Network! 🎉
        
    //     You’re officially on the waitlist, and we’re excited to have you as an early member of our community. We’re working hard to bring you valuable resources, insights, and opportunities to help you on your entrepreneurial journey.
        
    //     Stay tuned for updates, and rest assured, we’ll notify you as soon as you’re off the waitlist and ready to dive in. In the meantime, feel free to reach out if you have any questions or need support.
        
    //     Thanks again for your interest, and we can’t wait to connect with you soon!
        
    //     Best regards,
    //     Hamza
    //     Founder, Microsaas Founders Network`
    
    //         }),
    //         headers: { "Content-Type": "application/json" },
            
    //       });
    //       const result = await response.json();
    //       if (response.status === 200) {
    //         console.log("Email sent successfully");
    //       } else {
    //         console.error("Failed to send email:", result.error);
    //       }
    //     } catch (error) {
    //       console.error("Error sending email:", error);
    //     };


    } catch (error) {
      console.error("Error submitting form:", error);
      showAlert("There was an error submitting the form.");
    }
  };



  // EMAIL SUBMISSION WITH GOOGLE SHEETS




  
  

    // const handleSubmit = async (event) => {
    //     event.preventDefault(); // Prevent default form submission behavior

    //     const emailInput = formRef.current.querySelector("#emailInput");
    //     const email = emailInput.value;

    //     if (!email) {
    //         alert("Please enter a valid email!");
    //         return;
    //     }

    //     try {
    //         const response = await fetch("http://localhost:5000/submit-form", {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({ email }),
    //         });

    //         const result = await response.json();

    //         if (result.success) {
    //             alert("Welcome to the early Microsaas Founders network! We received your form submission.");
    //             emailInput.value = ""; // Clear the input after successful submission
    //         } else {
    //             alert("Failed to submit. Please try again later.");
    //         }
    //     } catch (error) {
    //         console.error("Error submitting form:", error);
    //         alert("There was an error submitting the form.");
    //     }
    //   };

    // data of site here dont change unless needed
    const website_text = {

        header : {
            logoText: 'Logo_Here',
            waitlistText: 'Waitlist'
        },

        main : {
            h1: 'Microsaas Founders: Connect, Collaborate, Build',
            coremessage1 : "Building a product is hard. Finding the right partner doesn't have to be."
        },

        body : {
            coremessage2 : "Hitting a wall? Break through with the right partner!",
            h2: "HOW WE HELP YOU BUILD"
        }, 

        services: [
            {
                title: "Build Faster Together: ",
                description: "Find co-founders with complementary skills to accelerate your growth."
            },

            {title: "Exclusive Community",
             description: "Join a vetted group of Micro-SaaS founders and share knowledge."
            },

            {title: "Find Your Ideal Co-Founder",
            description: "Connect with like-minded solo entrepreneurs to share ideas and resources with the help of our matchmaking algorithm.",
               }
        ],

        cta : { 
            coremessage3 : "Claim Your Spot In The Microsaas Founders Network!",
            h3: "JOIN THE WAITLIST",
            buttonText: "Sign Up"
        },

        footer: {
            text: "© 2024 MicroSaaS Founders. All rights reserved."
        }

    }



    return (
        <div className='full-page'>
          {/* Custom Alert Box */}
      <div id="custom-alert" className={`alert-container ${alertVisible ? 'show' : ''}`}>
        <div className="alert-box">
          <span id="alert-message">{alertMessage}</span>
          <button className="close-btn" onClick={closeAlert}>OK</button>
        </div>
      </div>
           {/* Header */}
      <div className="header">
        <img className="logo" src="./media/logo.png" alt="MicroSaaS Founders Logo - Solo Entrepreneurs Community" />
        <button className="waitlist" onClick={handleScrollToForm}>
          {website_text.header.waitlistText}
        </button>
      </div>

            <div className='main'> 
            <h1>
    {website_text.main.h1.split(":")[0]}: <br />
    {website_text.main.h1.split(":")[1].split(",")[0]}, 
    {website_text.main.h1.split(":")[1].split(",")[1]} <br />
    <span className='build'>{website_text.main.h1.split(":")[1].split(",")[2]}</span>
  </h1>
                <p>
                    {website_text.main.coremessage1.split("right")[0]}<br />
                   <span className='coremsg1'>right {website_text.main.coremessage1.split("right")[1]}</span> </p>
            </div>



            <div className="visuals">
  <div className='set-visual'>
  
  <video autoPlay muted loop className="background-video" controlsList="nodownload" playsInline>
        <source src="./media/particles16.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
  <svg className="circles-svg" width="1000" height="500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 500">
    <defs>
      {/* <!-- Define both gradients inside a single defs tag --> */}
      <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: '#f7ebed ', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#fca2b1', stopOpacity: 1 }} />
      </linearGradient>
      <linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: 'black', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: 'darkblue', stopOpacity: 1 }} />
      </linearGradient>
    </defs>

    {/* <!-- Apply gradients to circles --> */}
    <circle 
      className={`circle1 ${animate ? 'move' : ''} ${shake ? 'shake' : ''}`} 
      cx="35%" cy="50%" r="200" fill="url(#gradient1)" 
    />
    <circle 
      className={`circle2 ${animate ? 'move' : ''} ${shake ? 'shake' : ''}`} 
      cx="85%" cy="50%" r="200" fill="url(#gradient2)" 
    />
  </svg>
  <div className='text-whole'> <div className="text-container">
            <p className={`text1 ${isFadingOut ? 'fadeOut' : 'fadeIn'}`}>
              {circleTexts1[textIndex]}
            </p>
            <p className={`text2 ${isFadingOut ? 'fadeOut' : 'fadeIn'}`}>
              {circleTexts2[textIndex]}
            </p>
          </div></div>
 
          {/* <div className='particles'><ParticlesComponent
  id="particles"
  options={resetParticles ? resetParticleOptions : particleOptions}
/></div> */}
         
        </div>
      </div>
  
            
            <div className='body'>
                <div className='contents-body'>
                <p>{website_text.body.coremessage2}</p>
                <h2>{website_text.body.h2}</h2>
                </div>
                
            </div>

            <div className='services'>
            <div className='content-services'>
            <section>
          {website_text.services.map((service, index) => (
            <div className='service-block' key={index}>
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </section>

            </div>
           
                
            </div>
            <div ref={formRef} className="cta">
        <p>{website_text.cta.coremessage3}</p>
        <h2>{website_text.cta.h3}</h2>
        <form className="form" id="emailForm" onSubmit={handleSubmit}>
          <input 
            type="email" 
            id="emailInput" 
            placeholder="Your Email Address" 
            required 
            aria-label="Enter your email to join the waitlist" 
          />
          
          <button type="submit" id="submit">
            {website_text.cta.buttonText}
          </button>
        </form>
      </div>
      <div className='privacy'> <label>
    <input type="checkbox" id="consentCheckbox" required />
    I agree to the <a href="/privacy-policy" target="_blank">Privacy Policy</a> and consent to receiving emails.
  </label></div>
          
      {/* Footer */}
      <div className="footer">
        <img className="logo" src="./media/logo.png" alt="MicroSaaS Founders Logo - Community for Solo Entrepreneurs" />
        <p>{website_text.footer.text}</p>
      </div>
    </div>
  );
};

export default Home_Page;
