import './css/privacy.css';
import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom



const Privacy_Policy = () => {


    const website_text = {

        header : {
            logoText: 'Logo_Here',
            waitlistText: 'Waitlist'
        },
        footer: {
            text: "© 2024 MicroSaaS Founders. All rights reserved."
        }

    }


    
      const buttonStyle = {
        backgroundColor: '#7a2d8c',  // Purple button background
        color: 'white',
        fontSize: '1.5rem',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        textDecoration: 'none',  // Remove underline from the link
        transition: 'background-color 0.3s ease',
        marginTop: '5%'  // Add space between the text and button
      };

  // Add hover effect for button
  const buttonHoverStyle = {
    backgroundColor: '#5e2370'  // Darker purple on hover
  };

    return (
        <div className="privacy-page">
            
            <div className="header">
        <img className="logo" src="./media/logo.png" alt="MicroSaaS Founders Logo - Solo Entrepreneurs Community" />
       
      </div>
       {/* Link to homepage */}
       <Link to="/" style={{ ...buttonStyle }} 
          onMouseEnter={e => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor} 
          onMouseLeave={e => e.target.style.backgroundColor = buttonStyle.backgroundColor}>
          Home
        </Link>

            <header className="privacy-header">

                <h1>Privacy Policy</h1>
                <p>Your privacy is important to us. This policy explains how we handle your information.</p>
            </header>

            <main className="privacy-content">
                <section className="privacy-section">
                    <h2>1. Information We Collect</h2>
                    <p>We collect your email address when you sign up for our waitlist. This information is stored securely.</p>
                </section>

                <section className="privacy-section">
                    <h2>2. How We Use Your Information</h2>
                    <p>Your information is used to keep you updated about our services and improvements.</p>
                </section>

                <section className="privacy-section">
                    <h2>3. Third-Party Services</h2>
                    <p>We may use third-party services to manage our database, but your information will not be sold.</p>
                </section>

                <section className="privacy-section">
                    <h2>4. Your Rights</h2>
                    <p>You have the right to access, modify, or delete your personal data by contacting us.</p>
                </section>

                <section className="privacy-section">
                    <h2>5. Contact Us</h2>
                    <p>If you have any questions or concerns, please email us at <a href="mailto:microsaasfounders@gmail.com">microsaasfounders@gmail.com</a>.</p>
                </section>
            </main>

            <div className="footer-privacy">
        <img className="logo" src="./media/logo.png" alt="MicroSaaS Founders Logo - Community for Solo Entrepreneurs" />
        <p>{website_text.footer.text}</p>
      </div>

        </div>
    );
};

export default Privacy_Policy;
