import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home_Page from './components/home_page';
import Success_Page from './components/success_page';
import Not_Found from './components/not_found';
import Privacy_Policy from './components/privacy';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home_Page />} />
        <Route path="/waitlist_successful" element={<Success_Page />}/>
        <Route path="/privacy-policy" element={<Privacy_Policy />}/>
        <Route path="*" element={<Not_Found />} />
      </Routes>
    </Router>
  );
}

export default App;