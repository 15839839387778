import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import './css/structure_basic.css';

function Not_Found() {

  const textStyle = {
    fontFamily: "'Babue', sans-serif",
    fontSize: '4rem',
    color: 'white',
    textAlign: 'center',
    textShadow: '3px 3px 5px rgba(0, 0, 0, 0.8)',
    marginTop: '50px'
  };

  const fullPageStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'  // Full height of the viewport
  };

  const bodyStyle = {
    flex: 1,  // Allow this section to take up remaining space
    display: 'flex',
    flexDirection: 'column',  // Stack items vertically
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '20px'
  };

  const footerStyle = {
    padding: '20px',
    textAlign: 'center',  
    color: 'white',
    marginTop: 'auto'  // Push footer to the bottom
  };

  const buttonStyle = {
    backgroundColor: '#7a2d8c',  // Purple button background
    color: 'white',
    fontSize: '1.5rem',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textDecoration: 'none',  // Remove underline from the link
    transition: 'background-color 0.3s ease',
    marginTop: '20px'  // Add space between the text and button
  };

  // Add hover effect for button
  const buttonHoverStyle = {
    backgroundColor: '#5e2370'  // Darker purple on hover
  };

  return (
    <div className="full-page-success" style={fullPageStyle}>
      <div className="header-success">
        <img
          className="logo-success"
          src="./media/logo.png"
          alt="MicroSaaS Founders Logo"
        />
      </div>

      <div className="notfound" style={bodyStyle}>
        <h1 style={textStyle}>Page Not Found!</h1>
        
        {/* Link to homepage */}
        <Link to="/" style={{ ...buttonStyle }} 
          onMouseEnter={e => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor} 
          onMouseLeave={e => e.target.style.backgroundColor = buttonStyle.backgroundColor}>
          Home
        </Link>
      </div>

      <div className="footer-success" style={footerStyle}>
        <img
          className="logo-success"
          src="./media/logo.png"
          alt="MicroSaaS Founders Logo"
        />
        <p>© 2024 MicroSaaS Founders. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Not_Found;
