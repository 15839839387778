import './css/structure_basic.css';
import React, { useState } from 'react';
import Airtable from 'airtable';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom

function Success_Page() {

 // Alert state and functions
 const [alertVisible, setAlertVisible] = useState(false);  // Track alert visibility
 const [alertMessage, setAlertMessage] = useState('');

 // Function to show the custom alert
 const showAlert = (message) => {
   setAlertMessage(message);
   setAlertVisible(true);  // Display the alert
 };

 // Function to close the alert box
 const closeAlert = () => {
   setAlertVisible(false);  // Hide the alert
 };

  const [email, setEmail] = useState('');
  const [query, setQuery] = useState('');
  
  // Airtable setup
  const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;
  const base = new Airtable({ apiKey }).base('appKcpbUdsxyXqNcT'); // Your Airtable Base ID
  const table = base('Waitlist1'); // Your table name

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !query) {
      showAlert('Please fill in both the email and query fields.');
      return
      // setTimeout(() => {
      //   return; }, 3000); // Navigate after alert
    }

    if (!isValidEmail(email)) {
      showAlert('Please enter a valid email address.');
      return
    }

    try {
      // Check if email already exists in Airtable
      const records = await base('Waitlist1') // Replace 'Waitlist' with your table name
        .select({
          filterByFormula: `{Email} = "${email}"`,
        })
        .firstPage();

      // Get the current date for submission
      const date = new Date();  // Get current date and time
const submissionDate = date.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,  // true for 12-hour format, false for 24-hour
});

      if (records.length > 0) {
        // If email exists, update the record with the query and submission date
        const recordId = records[0].id; // ID of the existing record
        await table.update(recordId, {
          'Query': query, // Add your column name for the query
          'Query Submission Date': submissionDate, // Add your column name for the submission date
        });
        showAlert('Query successfully submitted!');
        
      } else {
        // If email doesn't exist, create a new record
        await base('Waitlist1').create({
          'Email': email,
          'Query': query,
          'Email Submission Date': submissionDate,
          'Query Submission Date': submissionDate,
        });
        showAlert('Query successfully submitted!');
        
        // setTimeout(() => {
        //   return; }, 3000); // Navigate after alert
      }

      // Reset the form after submission
      setEmail('');
      setQuery('');
    } catch (error) {
      console.error('Error handling form submission:', error);
      showAlert('There was an error submitting the form. Please try again later.');
    };
    
  };



  const website_text = {
    header: { logoText: 'Logo_Here', waitlistText: 'Waitlist' },
    body: {
      heading: "Thank you for joining the MicroSaaS Founders Waitlist!",
      body: "You’re officially on the list! We’ll send you updates, early access to exclusive content, and information about upcoming events.",
      body2: "We're thrilled to have you on board. Stay tuned for updates and actionable insights designed to help you find a co-founder, and grow your own MicroSaaS project.",
    },
    form: {
      text: "Have any questions regarding our service? Fill in the form below and we will get back to you!",
    },
    footer: {
      text: "© 2024 MicroSaaS Founders. All rights reserved.",
    },
  };

  const buttonStyle = {
    backgroundColor: '#7a2d8c',  // Purple button background
    color: 'white',
    fontSize: '1rem',
    padding: '5px 15px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textDecoration: 'none',  // Remove underline from the link
    transition: 'background-color 0.3s ease',
    marginTop: '20px'  // Add space between the text and button
  };

  // Add hover effect for button
  const buttonHoverStyle = {
    backgroundColor: '#5e2370'  // Darker purple on hover
  };
  

  return (
    <div className="full-page-success">
          {/* Custom Alert Box */}
          <div id="custom-alert" className={`alert-container ${alertVisible ? 'show' : ''}`}>
        <div className="alert-box">
          <span id="alert-message">{alertMessage}</span>
          <button className="close-btn" onClick={closeAlert}>OK</button>
        </div>
      </div>
      <div className="header-success">
        <img
          className="logo-success"
          src="./media/logo.png"
          alt="MicroSaaS Founders Logo"
        />
        
      </div>
     <div className='home-success'>
     <Link to="/" style={{ ...buttonStyle }} 
          onMouseEnter={e => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor} 
          onMouseLeave={e => e.target.style.backgroundColor = buttonStyle.backgroundColor}>
          Home
        </Link>
     </div>

      <div className="body-success">
        <h1>{website_text.body.heading}</h1>
        <div className='box-text'><p>{website_text.body.body}</p>
        <p>{website_text.body.body2}</p></div>
        
      </div>

      <div className="form-success">
        <p>{website_text.form.text}</p>

        <form onSubmit={handleSubmit}>
          <input
            className="email-form-success"
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email here..."
          />
          <input
            className="query-form-success"
            type="text"
            value={query}
            onChange={handleQueryChange}
            placeholder="Enter your query here..."
          />
          <button className="form-button-success" type="submit">
            Submit
          </button>
        </form>
      </div>

      <div className="footer-success">
        <img
          className="logo-success"
          src="./media/logo.png"
          alt="MicroSaaS Founders Logo"
        />
        <p>{website_text.footer.text}</p>
      </div>
    </div>
  );
}

export default Success_Page;
